
		import React from 'react';

		function PenIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 512, 512">
					<path d="M497.9 74.16L437.8 14.06C428.5 4.688 416.2 0 403.9 0s-24.59 4.688-33.97 14.06L31.04 352.1c-2.234 2.234-3.759 5.078-4.376 8.176l-26.34 131.7C-1.708 502.1 6.159 512 15.95 512c1.047 0 2.116-.1033 3.198-.3202l131.7-26.34c3.098-.6191 5.941-2.141 8.175-4.373l338.9-338.9C516.7 123.3 516.7 92.91 497.9 74.16zM147.7 469.6l-131.7 26.35l26.34-131.7l267.3-267.3l105.4 105.4L147.7 469.6zM486.6 130.8l-60.28 60.27l-105.4-105.4l60.26-60.27c6.047-6.045 14.09-9.375 22.66-9.375s16.61 3.33 22.66 9.375l60.09 60.1C499.1 97.96 499.1 118.3 486.6 130.8z" />
				</svg>
			);
		}

		export default PenIcon;
	