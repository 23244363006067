
		import React from 'react';

		function CodeIcon() {
			return (
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0, 0 640, 512">
					<path d="M157.8 114.5c-3-3.219-8.062-3.375-11.31-.3125l-144 136C.9062 251.7 0 253.8 0 256c0 2.188 .9062 4.316 2.5 5.816l144 136c4.016 3.775 9.111 2.021 11.31-.3125c3.031-3.219 2.906-8.281-.3125-11.31L19.66 256L157.5 125.8C160.7 122.8 160.8 117.7 157.8 114.5zM637.5 250.2l-144-136c-3.25-3.063-8.281-2.906-11.31 .3125s-2.906 8.281 .3125 11.31l137.8 130.2l-137.8 130.2c-3.219 3.031-3.344 8.094-.3125 11.31c2.201 2.334 7.296 4.088 11.31 .3125l144-136C639.1 260.3 640 258.2 640 256C640 253.8 639.1 251.7 637.5 250.2zM399.9 .3644c-4.234-1.313-8.703 1.031-10.03 5.25l-155 496c-1.312 4.219 1.031 8.688 5.25 10C240.9 511.9 241.7 512 242.5 512c3.406 0 6.562-2.188 7.641-5.625l155-496C406.5 6.146 404.1 1.677 399.9 .3644z" />
				</svg>
			);
		}

		export default CodeIcon;
	