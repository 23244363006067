import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import PageHeader from '../../components/PageHeader';
import Section from '../../components/Section';
import Grid from '../../components/Styles/Grid';
import Box from '../../components/Styles/Box';
import Heading from '../../components/Styles/Heading';
import Text from '../../components/Styles/Text';
import CardBasic from '../../components/CardBasic';
import Inner from '../../components/Styles/Inner';
import SingleLineTextWithIcon from '../../components/SingleLineTextWithIcon';
import Chip from '../../components/Styles/Chip';
import PenIcon from '../../components/Icons/Fa/Thin/PenIcon';
import CodeIcon from '../../components/Icons/Fa/Thin/CodeIcon';

import codeLines from '../../images/code-lines.svg';

function managed() {
  return (
    <Layout>
      <SEO title="Web Development" />
      <PageHeaderStyled
        heading="Custom Web Development"
        subHeading="Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet id officia perspiciatis architecto sint nemo nobis accusantium cumque ratione. Quisquam beatae corporis est vero debitis?"
        buttonText="Request a Free Quote"
        buttonLink="/contact/"
      />
      <PageNavigation>
        <Grid
          as="nav"
          gridTemplateColumns="repeat(7, minmax(200px, 1fr))"
          gridGap={2}
        >
          <SingleLineTextWithIcon icon={<PenIcon />} text="Web Design" />
          <SingleLineTextWithIcon icon={<CodeIcon />} text="Web Development" />
          <SingleLineTextWithIcon icon={<CodeIcon />} text="Website Packages" />
          <SingleLineTextWithIcon
            icon={<CodeIcon />}
            text="White Label Websites"
          />
        </Grid>
      </PageNavigation>
    </Layout>
  );
}

const SectionGray = styled(Section)`
  background: var(--lightGray);
  /* background: var(--lightGray) url(${codeLines}) no-repeat right -500px top; */
`;

const PageHeaderStyled = styled(PageHeader)`
  background: url(${codeLines}) no-repeat right -100px top;
  min-height: 35vh;
`;

const PageNavigation = styled(Section)`
  padding: 1em 0;
  background: var(--darkBlue);
  color: white;
`;

export default managed;
