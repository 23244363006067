import React, { Component } from 'react';
import styled from 'styled-components';
import Card from './Styles/Card';
import Heading from './Styles/Heading';
import Text from './Styles/Text';
import LinkIcon from './LinkIcon';

const CardBasic = ({
  className,
  icon,
  description,
  linkText,
  link,
  linkAltText,
  heading,
  boxShadow,
}) => {
  return (
    <CardStyled className={className} link={link} boxShadow={boxShadow}>
      {icon && <IconWrap>{icon}</IconWrap>}
      <HeadingStyled as="h3">{heading}</HeadingStyled>
      {description && (
        <Text dangerouslySetInnerHTML={{ __html: `${description}` }} />
      )}
      {linkText && <LinkIcon>{linkText}</LinkIcon>}
      <Link href={link}>
        <span class="visually-hidden">{linkAltText}</span>
      </Link>
    </CardStyled>
  );
};

const CardStyled = styled(Card)`
  :hover ${LinkIcon} {
    color: var(--secondary);
  }
`;

const HeadingStyled = styled(Heading)`
  font-size: 1.4em;
`;

const Link = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
const LinkText = styled.a`
  text-decoration: underline;
  color: var(--secondary);
`;

const IconWrap = styled.span`
  display: inline-block;
  width: 45px;
  height: 45px;
  margin-bottom: 1em;
`;

export default CardBasic;
